import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Tooltip,
  Button,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bashUrl = process.env.REACT_APP_BASH_URL;

function EmployeeLeaveList() {
  const options = {
    Authorization: `Bearer ${localStorage.getItem("shinpay-vendor-token")}`,
    "Content-Type": "application/json",
  };
  
  const user = useSelector((state) => state.user.user);
  
  const [employeeLeaves, setEmployeeLeaves] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [customVariant, setCustomVariant] = useState("success");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchEmployeeLeaves = async () => {
    try {
      const response = await axios.get(`${bashUrl}/leaves/employee-leaves-list`, { headers: options });
      console.log('response' , response.data)
      setEmployeeLeaves(response.data);
      setLoading(false);
    } catch (error) {
      setSuccess("");
      setError(error?.response?.data?.msg || "Failed to fetch data.");
      setCustomVariant("error");
      setOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeeLeaves();
  }, []);

  return (
    <Paper sx={{ borderRadius: 0, width: '100%', ml: 1, p: 2 }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
          {error ? error : success}
        </Alert>
      </Snackbar>

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ width: '100%', mb: 2 }}>
        {/* Link to redirect the user */}
        <Link to="/leave-management/add" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">Credit Leaves</Button>
        </Link>
      </Box>
      <Box sx={{ width: '100%' }}>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#E4EAEE", color: 'white' }}>
                <TableCell sx={{ color: 'black' }}>Employee Name</TableCell>
                <TableCell sx={{ color: 'black' }} align="center">Email</TableCell>
                <TableCell sx={{ color: 'black' }} align="center">Sick Leave (Allotted/Consumed)</TableCell>
                <TableCell sx={{ color: 'black' }} align="center">Casual Leave (Allotted/Consumed)</TableCell>
                <TableCell sx={{ color: 'black' }} align="center">Earned Leave (Allotted/Consumed)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeLeaves?.length ? (
                employeeLeaves?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((employee, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{employee.name}</TableCell>
                    <TableCell align="center">{employee.email}</TableCell>
                    <TableCell align="center">
                      {employee.sickLeave.allotted} / {employee.sickLeave.consumed}
                    </TableCell>
                    <TableCell align="center">
                      {employee.casualLeave.allotted} / {employee.casualLeave.consumed}
                    </TableCell>
                    <TableCell align="center">
                      {employee.earnedLeave.allotted} / {employee.earnedLeave.consumed}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography>No employee leave data found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={employeeLeaves.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default EmployeeLeaveList;
